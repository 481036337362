import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../components/Dashboard/DashboardWrapper';
import Layout from '../../components/layout';
import {
  withAuthorization,
} from '../../components/Session';
import HeadData from "../../data/HeadData"
import BackButton from '../../components/Common/BackButton';
import PowerpointsSectionNew from '../../components/Sections/PowerpointsSectionNew';

const PowerpointsPageBase = () => (
  <DashboardWrapper pageName='Powerpoints'>
    <div className="relative pt-4 pb-32 md:pt-12 2xl:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <PowerpointsSectionNew />

        <BackButton buttonLink='/sales-support-documents' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const PowerpointsPage = compose(
  withAuthorization(condition),
)(PowerpointsPageBase);

const Powerpoints = () => (
  <Layout>
    <HeadData title='Powerpoints' />
    <PowerpointsPage />
  </Layout>
)

export default Powerpoints
