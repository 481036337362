import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import DocumentCard from '../Common/DocumentCard';

const PowerpointsSectionNew = () => {
  const data = useStaticQuery(graphql`
    query PowerpointsSectionNewQuery {
      prismicSalesSupportDocumentsPowerpoint {
        data {
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
          }
        }
      }
    }
  `);

  const doc = data.prismicSalesSupportDocumentsPowerpoint

  return (
    <div className="grid grid-cols-1 gap-8 mt-16">
      {doc.data.pdf_files_list.map(item => {
        return (
          <DocumentCard
            cardTitle={item.pdf_file_title}
            buttonLink={item.pdf_download_link.url}
          />
        )
      })}
    </div>
  )
};

export default PowerpointsSectionNew;
